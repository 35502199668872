import { PinsaSubscriptionResponse } from '@/types/pinsaTypes';
import { MutationCustomerRecoverArgs } from '@/types/shopify.type';
import { Address, OrderUpdate, RegisterFunnelUserData } from '@/types/types';
import { generateHmac, shopifyGraphQLClient } from '@/utils';
import Axios, { AxiosError } from 'axios';
import { customerRecoverEmail } from './graphql/shopify/mutations/user';
import { axios } from './utils';

interface userDetailsParams {
  email?: string;
  phone?: string;
  accepts_free_gifts?: boolean;
}

export const customerRecoverByEmail = (email: string) =>
  shopifyGraphQLClient.request<MutationCustomerRecoverArgs>(customerRecoverEmail, {
    email: `${email}`,
  });

export const getUserDetails = async (defaultAddress = false) => {
  try {
    const result = await axios.get(
      `/api/v1/customers/details${defaultAddress ? '?default_address=true' : ''}`
    );
    return result.data;
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

interface UpdateUserDetailsError {
  message: string;
}

export const updateUserDetails = async (userDetails: userDetailsParams) => {
  try {
    const result = await axios.put(`/api/v1/customers`, userDetails);
    return result.data;
  } catch (error) {
    throw new Error(
      ((error as AxiosError)?.response?.data as UpdateUserDetailsError)?.message ?? 'Something went wrong'
    );
  }
};

export const getUserAddresses = async () => {
  try {
    const result = await axios.get(`/api/v1/addresses`);
    return result.data;
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const createNewUserAddress = (address: Address) => {
  try {
    return axios.post(`/api/v1/addresses`, address);
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const deleteUserAddress = async (addressId: string) => {
  try {
    const result = await axios.delete(`/api/v1/addresses/${addressId}`);
    return result.data;
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const getUserAddressById = async (addressId: string) => {
  try {
    const result = await axios.get(`/api/v1/addresses/${addressId}`);
    return result.data;
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const editUserAddress = async ({ addressId, address }: { addressId: string; address: Address }) => {
  try {
    const result = await axios.put(`/api/v1/addresses/${addressId}`, address);
    return result.data;
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const setDefaultAddress = (addressId: string) => {
  try {
    return axios.post(`/api/v1/addresses/${addressId}/default`);
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const updateSubscriptionOrder = async ({ id, lines }: { id: string; lines: OrderUpdate[] }) => {
  try {
    const result = await axios.put(`/api/v1/subscriptions/${id}`, { lines_attributes: lines });
    return result.data;
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const logoutShopifyUser = () => {
  try {
    return axios.delete(`/api/v1/sessions`);
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const getSubscriptions = () => {
  try {
    return axios.get(`/api/v1/subscriptions`);
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const getOrders = (page: number, perPage: number) => {
  return Axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/orders?per_page=${perPage}&page=${page}`, {
    withCredentials: true,
  });
};

export const getOrderById = (id: string) => {
  try {
    return axios.get(`/api/v1/orders/${id}`);
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const getSubscription = async (id: string): Promise<PinsaSubscriptionResponse> => {
  try {
    const result = await axios.get(`/api/v1/subscriptions/${id}`);
    return result.data as PinsaSubscriptionResponse;
  } catch (e) {
    throw new Error(e?.toString() ?? 'Something went wrong');
  }
};

export const pauseSubscription = ({
  id,
  duration,
  reason,
}: {
  id: string;
  duration: number;
  reason: string;
}) => {
  try {
    return axios.post(`/api/v1/subscriptions/${id}/pause`, {
      pause_reason: reason,
      pause_duration: duration,
    });
  } catch (e) {
    throw new Error(e?.toString() ?? 'Something went wrong');
  }
};

export const activateSubscription = (id: string) => {
  try {
    return axios.post(`/api/v1/subscriptions/${id}/activate`, { reason: 're-activate' });
  } catch (e) {
    throw new Error(e?.toString() ?? 'Something went wrong');
  }
};

export const cancelSubscription = ({ id, reason }: { id: string; reason: string }) => {
  try {
    return axios.post(`/api/v1/subscriptions/${id}/cancel`, { cancellation_reason: reason });
  } catch (e) {
    throw new Error(e?.toString() ?? 'Something went wrong');
  }
};

export const requestUpdatePaymentMethod = (id: string) => {
  return Axios.post(
    `${import.meta.env.VITE_API_BASE_URL}/api/v1/payment_methods/${id}/request_update`,
    {},
    {
      withCredentials: true,
    }
  );
};

export const sendItNow = (id: string) => {
  try {
    return axios.post(`/api/v1/subscriptions/${id}/send_it_now`);
  } catch (e) {
    throw new Error(e?.toString() ?? 'Something went wrong');
  }
};

export const previewNextSubscriptionDate = (id: string) => {
  return Axios.get(
    `${import.meta.env.VITE_API_BASE_URL}/api/v1/subscriptions/${id}/preview_next_billing_date`,
    { withCredentials: true }
  );
};

export const getCheckUser = async (email: string) => {
  try {
    const currentTimestamp = Math.floor(Date.now() / 1000).toString();
    const hmac = generateHmac(email, currentTimestamp);
    return axios.post(`/api/v1/user_check`, { email, hmac, timestamp: currentTimestamp });
  } catch (error) {
    throw new Error(error?.toString() ?? 'Something went wrong');
  }
};

export const registerFunnelUser = (payload: RegisterFunnelUserData) => {
  try {
    return axios.post(`/api/v1/register_funnel`, payload);
  } catch (e) {
    throw new Error(e?.toString() ?? 'Something went wrong');
  }
};
